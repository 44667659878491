<template>
  <div class="dayMessageList">
    <div class="page-title">{{ $t('pageTitle.dayMessages') }}</div>
    <div class="actionBar">
      <div class="actionButton bgBrown refresh" @click="loadDayMessages"><i class="fas fa-sync"></i>
        <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
      </div>
    </div>
    <div class="topNav">
      <router-link to="/settings"><div class='navButton'><i class="fas fa-arrow-left"/> {{ $t('general.back') }}</div></router-link>
    </div>
    <div class="screen-container" ref="screenContainer" :style='{ height: screenContainerHeight }'>
      <EmptyListAnimationComponent v-if="dayMessages != null && dayMessages.length == 0"/>
      <div class="listWrapper">
        <div class="listItemWrapper" v-for="entry in dayMessages" :key="entry._id">
          <div class="listItem dayMessage" @click="editDayMessage(entry)">
            <div v-if='entry.endpoints.includes("web")' class="icon"><i class="fas fa-desktop"/></div>
            <div v-if='entry.endpoints.includes("colada")' class="icon"><i class="fas fa-tablet-alt"/></div>
            {{ entry.title == null ? '' : (entry.title.substring(0,20) + (entry.title.length > 20 ? '...' : '')) }}
            {{ entry.validUntil != null ? new Date(Date.parse(entry.validUntil)).toLocaleString() : '' }}</div>
          <div class="listActionWrapper">
            <span class="listAction userGroupAction" @click="editDayMessage(entry)"><i class="fas fa-pen"/></span>
            <span class="listAction dayMessageAction" @click="deleteDayMessage(entry._id)"><i class="fas fa-trash"/></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dayMessages != null && dayMessages.length == 0" class="floating-add-button" @click="addDayMessage">
      <img class="floating-add-button-icon" src="../assets/icons/plus_white.png" />
    </div>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
    <CreateOrEditDayMessageModal :dayMessageToEdit="dayMessageToEdit" ref="createOrEditDayMessageModal" @refresh="loadDayMessages"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import CreateOrEditDayMessageModal from '@/components/modals/CreateOrEditDayMessageModal.vue';
import EmptyListAnimationComponent from '@/components/EmptyListAnimationComponent.vue';

export default {
  name: 'DayMessageListComponent',
  props: [],
  mixins: [ScreenHeightMixin],
  components: {
    ConfirmModal,
    CreateOrEditDayMessageModal,
    EmptyListAnimationComponent,
  },
  data() {
    return {
      dayMessages: [],
      dayMessageToEdit: null,
    };
  },
  watch: {
  },
  mounted() {
    this.loadDayMessages();
  },
  methods: {
    async loadDayMessages() {
      this.dayMessages = [];
      try {
        const data = await ServiceHelper.loadDataFromBackend('dayMessages/all');
        data.payload.forEach((entry) => {
          try {
            const e = entry;
            if (entry.title != null && entry.title.length > 0) e.title = CryptoHelper.decrypt(e.title);
            if (entry.description != null && entry.description.length > 0) e.description = CryptoHelper.decrypt(e.description);
            // e.validUntil = e.validUntil != null ? new Date(Date.parse(e.validUntil)).toLocaleString() : null;
          } catch (e) {
            console.error(e);
          }
        });
        this.dayMessages = data.payload;
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async addDayMessage() {
      this.dayMessageToEdit = { };
      await this.$refs.createOrEditDayMessageModal.show();
      return null;
    },
    async editDayMessage(dayMessage) {
      this.dayMessageToEdit = JSON.parse(JSON.stringify(dayMessage));
      await this.$refs.createOrEditDayMessageModal.show();
      return null;
    },
    async deleteDayMessage(dayMessageId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `dayMessage/${dayMessageId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadDayMessages();
        } catch (error) {
          console.error(error);
        }
      }
      return null;
    },
  },
  computed: {
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}

</style>
