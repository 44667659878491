<template>
  <div>
    <DayMessageListComponent/>
  </div>
</template>

<script>
import DayMessageListComponent from '@/components/DayMessageListComponent.vue';

export default {
  name: 'DayMessagesView',
  components: {
    DayMessageListComponent,
  },
  props: [],
  mounted() {

  },
};
</script>
