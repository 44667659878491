<template>
  <CustomModal v-model="showDayMessageModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{dayMessage != null && dayMessage._id != null ? $t('modal.titleEditDayMessage') : $t('modal.titleCreateDayMessage')}}</template>
    <div class="form" v-if="dayMessage != null">
      <div class="row" >
        <div class="label" >{{ $t('modal.labelTitle') }}:</div>
        <div class="value"><input ref="dayMessageTitle" v-model="dayMessage.title" type="text"/></div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelDescription') }}:</div>
        <div class="value"><input ref="dayMessageDescription" v-model="dayMessage.description" type="text" /></div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelShouldInvalidates') }}:</div>
        <div class="value"><input v-model="showDayMessageInvalidation" type="checkbox"/></div>
      </div>
      <div class="row" v-if="showDayMessageInvalidation">
        <div class="label">{{ $t('modal.labelInvalidateDateTime') }}:</div>
        <div class="value"><input v-model="dayMessage.validUntil" type="datetime-local"/></div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelShowOnWeb') }}:</div>
        <div class="value"><input v-model="showOnWeb" type="checkbox"/></div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelShowOnColada') }}:</div>
        <div class="value"><input v-model="showOnColada" type="checkbox"/></div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import Constants from '@/helpers/Constants';

export default {
  name: 'CreateOrEditDayMessageModal',
  props: ['dayMessageToEdit'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showDayMessageModal: false,
      dayMessage: null,
      showDayMessageInvalidation: false,
      selectedDayMessage: '-1',
      showOnWeb: true,
      showOnColada: true,
    };
  },
  watch: {
    dayMessageToEdit(newVal) {
      this.dayMessage = newVal;
      if (this.dayMessage != null) {
        this.showDayMessageInvalidation = this.dayMessage.validUntil != null;
        this.dayMessage.validUntil = this.dayMessage.validUntil != null
          ? Constants.formatDateForBrowserControl(new Date(Date.parse(this.dayMessage.validUntil)))
          : Constants.formatDateForBrowserControl(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000));
        // console.log('this.dayMessage.validUntil', this.dayMessage.validUntil);
      }
    },
  },
  mounted() {
  },
  methods: {
    async show() {
      this.showDayMessageModal = true;
    },
    hide() {
      this.showDayMessageModal = false;
    },
    async confirm() {
      if (this.dayMessage.title == null || this.dayMessage.title.trim() === '') {
        return;
      }

      try {
        if (this.showDayMessageInvalidation && Date.parse(this.dayMessage.validUntil) < new Date().getTime()) {
          // valid date is before now -> would be deleted immediately
          return;
        }
        this.dayMessage.title = CryptoHelper.encrypt(this.dayMessage.title);
        this.dayMessage.description = CryptoHelper.encrypt(this.dayMessage.description);
        const showEndpoints = [];
        if (this.showOnWeb) {
          showEndpoints.push('web');
        }
        if (this.showOnColada) {
          showEndpoints.push('colada');
        }
        this.dayMessage.endpoints = showEndpoints.join(',');
        this.dayMessage.validUntil = this.showDayMessageInvalidation ? new Date(Date.parse(this.dayMessage.validUntil)) : null;
        if (this.dayMessage._id != null) {
          await ServiceHelper.sendDataToBackend(`dayMessage/${this.dayMessage._id}`, this.dayMessage, 'PUT');
        } else {
          await ServiceHelper.sendDataToBackend('dayMessage', this.dayMessage);
        }
        this.$emit('refresh');
      } catch (error) {
        console.error(error);
      }
      this.hide();
    },
    cancel() {
      this.hide();
    },
    autoFocus() {
      if (!this.$refs.dayMessageName) {
        setTimeout(this.autoFocus, 200);
      } else {
        this.$refs.dayMessageName.focus();
      }
    },
  },
};
</script>

<style scoped>
::v-deep(.value table) {
  width: 100%;
}

::v-deep(.label) {
  vertical-align: middle;
}

::v-deep(.value th) {
  background: lightgray;
}

::v-deep(.value td) {
  border-bottom: 1px solid lightgray;
}

::v-deep(.value select) {
  width: 100%;
}

::v-deep(.removeLink) {
  font-size: 12px;
  cursor: pointer;
  color: gray;
}

</style>
